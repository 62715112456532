import { BiTrash as Bin } from '@react-icons/all-files/bi/BiTrash'
import React from 'react'
import { DocumentStatus } from '../../graphql/generated/schema'
import { ExternalLink } from '../ExternalLink'
import { LinkButtonWithIcon } from '../LinkButtonWithIcon'
import styles from './DocumentStatusInformation.module.scss'

export interface DocumentStatusInformationProps {
  /** Document status */
  documentStatus: DocumentStatus
  /** Prop function to handle document delete. */
  handleDeleteDocument: () => void
}

/** Display bundle information in document editing screen .*/
export const DocumentStatusInformation: React.FunctionComponent<
  DocumentStatusInformationProps
> = ({ documentStatus, handleDeleteDocument }) => {
  let documentText = ''
  let serviceNowHref = ''
  let knowledgeBaseId = ''

  /** TODO - apply correct KB id and HREF */

  switch (documentStatus) {
    case DocumentStatus.Corrupt:
      documentText = 'is corrupt'
      serviceNowHref =
        'https://dwp.service-now.com/place/?id=kb_article_view&sys_id=2b65537c1b60d950513bbb77d34bcb51'
      knowledgeBaseId = 'KB0039093'
      break
    case DocumentStatus.Invalid:
      documentText = 'is invalid'
      serviceNowHref =
        'https://dwp.service-now.com/place/?id=kb_article_view&sys_id=2b65537c1b60d950513bbb77d34bcb51'
      knowledgeBaseId = 'KB0039093'
      break
    case DocumentStatus.Error:
      documentText = 'contains errors'
      serviceNowHref =
        'https://dwp.service-now.com/place/?id=kb_article_view&sys_id=2b65537c1b60d950513bbb77d34bcb51'
      knowledgeBaseId = 'KB0039093'
      break
  }

  return (
    <div className={styles.container}>
      <div className={styles.informationContent}>
        <h1>
          This document {documentText} and will not be included in the bundle
        </h1>
        <p>
          Please follow the{' '}
          <ExternalLink
            label={`DWP Service Now Knowledge Base Article ${knowledgeBaseId}`}
            href={serviceNowHref}
          >
            {knowledgeBaseId}
          </ExternalLink>{' '}
          to know how to proceed.
        </p>
        <LinkButtonWithIcon
          icon={<Bin size={22} />}
          onClick={handleDeleteDocument}
        >
          Delete document
        </LinkButtonWithIcon>
      </div>
    </div>
  )
}
